body {
  margin: 0;
  font-family: Nunito Sans, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

.hidden-scroll {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  overflow-x: hidden;
}

.hidden-scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.pointer {
  cursor: pointer;
}

/* Google Autocomplete container */
.pac-container {
  z-index: 10000 !important;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.d-flex {
  display: flex;
}

.custom-scroll {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background: #E2E3E4;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #E2E3E4;
  }
}

.lowerCase {
  text-transform: lowercase;
}
